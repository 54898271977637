import React from 'react'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'

import Layout from '../../components/layout'
import StickyNav from '../../components/Navigation/StickyNav'

import HoverLink from '../../components/Contact/HoverLink'
import HoverTracker from '../../components/Contact/HoverTracker'
import { PageContainer } from '../../components/Contact/Containers'

const metaTitle = 'Contact Us - Leading Digital Product Studio | Crowdlinker'

const metaDescription =
  'Looking for a technology partner to help guide your company through digital transformation or build out a new digital product? Reach out to Crowdlinker with all your inquiries.'

const Container = styled(PageContainer)`
  @media (min-width: ${props => props.theme.screenWidth.large}) {
    max-width: 900px;
  }
`
const Header = styled('h1')`
  margin-bottom: 53px;
  @media (max-width: ${props => props.theme.screenWidth.medium}) {
    margin-bottom: 40px;
  }
`

const Contact = () => (
  <Layout page="contact">
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
    </Helmet>

    <StickyNav />

    <Container>
      <Header>What can we help with today?</Header>
      <HoverTracker>
        <HoverLink>
          <Link to="contact/project-inquiries">
            <h4>Project Inquiries</h4>
            <p>
              I’m looking to develop a website, app, or other new technology.
            </p>
          </Link>
        </HoverLink>
        <HoverLink>
          <Link to="contact/general-inquiries">
            <h4>General Inquiries</h4>
            <p>I have a general inquiry about Crowdlinker.</p>
          </Link>
        </HoverLink>
        <HoverLink>
          <Link to="contact/event-inquiries">
            <h4>Partnerships & Events</h4>
            <p>I want to discuss a partnership or an upcoming event.</p>
          </Link>
        </HoverLink>
        <HoverLink>
          <Link to="contact/media-inquiries">
            <h4>Press & Media</h4>
            <p>I’m looking to collect more information about Crowdlinker.</p>
          </Link>
        </HoverLink>
      </HoverTracker>
    </Container>
  </Layout>
)

export default Contact
