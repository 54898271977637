import styled from '@emotion/styled'

const HoverLink = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  max-width: 355px;
  margin: 15px auto;
  position: relative;

  a {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    background: #000;
    padding: 0 20px;
    border-radius: 5px;
    text-decoration: none;
  }

  border-radius: 5px;

  background: ${props =>
    props.active
      ? 'linear-gradient(116deg, #601947, #315567)'
      : 'rgba(255, 255, 255, 0.25)'};

  p {
    display: none;
    font-size: 22px;
    line-height: 1.23;
    text-align: center;
    color: #ffffff;
    padding: 0;
    margin: 0;
  }

  h4 {
    font-family: ${props => props.theme.fonts.sourceCode};
    font-size: 14px;
    letter-spacing: 0.9px;
    transition: color 0.6s;
    color: ${props => (props.active ? '#ffffff' : '#989898')};
    text-transform: uppercase;
  }

  @media (min-width: ${props => props.theme.screenWidth.medium}) {
    width: 312px;
    height: 160px;

    a {
      padding: 0 15px;
      justify-content: center;
    }

    h4 {
      font-size: 18px;
      text-align: center;
      margin-bottom: 12px;
    }

    p {
      display: block;
    }
  }

  @media (min-width: ${props => props.theme.screenWidth.large}) {
    width: 370px;
    height: 165px;
  }
`

export default HoverLink
