import { useState, cloneElement, Children } from 'react'

// injects active boolean prop to child element on mouse enter
function HoverTracker({ children }) {
  const [hoverIndex, setHoverIndex] = useState(0)

  return Children.map(children, (element, index) => {
    return cloneElement(element, {
      active: index === hoverIndex,
      onMouseEnter: () => setHoverIndex(index),
      onMouseLeave: () => setHoverIndex(null),
    })
  })
}

export default HoverTracker
